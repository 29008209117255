import * as React from "react"
import { Badge, Col, Row, Carousel } from "react-bootstrap"

import { year } from "./blog-post.module.css"

const DATE_SHORT_MONTHS = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
]

const NewBadge = () => (
  <span className="small ms-2 align-top">
    <Badge variant="success">NEW!</Badge>
  </span>
)

const CarouselWidget = ({ title, images }) => {
  return (
    <Carousel interval={null}>
      {images.map(({ image }, i) => (
        <Carousel.Item key={i}>
          <img className="d-block w-100" src={image} alt={title} />
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

const BlogPost = ({ frontmatter, html }) => {
  const date = new Date(frontmatter.date),
    today = new Date(),
    // Get the date from today, a week ago
    lastWeek = Date.parse(
      new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
    )

  return (
    <Row as="article">
      <Col md={3} as="section">
        <p>
          {`${DATE_SHORT_MONTHS[date.getMonth()]} ${date.getDate()}`}
          <span className={`${year} text-muted`}>{date.getFullYear()}</span>
        </p>
      </Col>
      <Col className="text-start" as="section">
        <h2>
          {frontmatter.title}
          {date.getTime() > lastWeek ? <NewBadge /> : null}
        </h2>
        <div className="border-bottom w-25 mb-3"></div>
        {frontmatter.carousels && frontmatter.carousels.map((carousel, i) => (
          <div className="mb-3">
            <h4>{carousel.title}</h4>
            <p>{carousel.description}</p>
            <CarouselWidget key={i} {...carousel} />
          </div>
        ))}
        <section className="small" dangerouslySetInnerHTML={{ __html: html }} />
      </Col>
    </Row>
  )
}

export default BlogPost
