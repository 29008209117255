import * as React from "react";
import { graphql } from "gatsby";

import BlogPost from "../components/blog-post";
import Layout from "../components/layout";
import Seo from "../components/seo";

const BlogIndexPage = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes;

  return (
    <Layout title={"Blog"}>
      <Seo title={"Blog"} />
      {posts.map((post) => (
        <BlogPost key={post.id} {...post} />
      ))}
    </Layout>
  );
};

export default BlogIndexPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(blog)/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        id
        html
        frontmatter {
          title
          date
          carousels {
            title
            description
            images {
              image
            }
          }
        }
      }
    }
  }
`;
